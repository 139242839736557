import React from 'react'
import Head from 'next/head'
import { SeoProps } from '~types'
import { BASE_URLS, isProd } from '~constants'
import { usePathname } from 'next/navigation'

const SEO = ({
  allowSearchEnginesToShowThisPage,
  defaultTitle,
  ogImage,
  metaDescription,
  seoTitle,
  shouldSearchEnginesFollowLinks,
  ogUrl,
  ogTitle,
  ogType,
  ogDescription
}: SeoProps) => {
  const pathName = usePathname()
  const baseUrl = BASE_URLS[process.env.NEXT_PUBLIC_APP_ENV] || BASE_URLS.prod

  const pageTitle = seoTitle || `${defaultTitle} | Sphere`
  const robotsValues = []
  const robotsIndex = allowSearchEnginesToShowThisPage && isProd ? 'index' : 'noindex'
  const robotsFollow = shouldSearchEnginesFollowLinks && isProd ? 'follow' : 'nofollow'
  const pageUrl = `${baseUrl}${pathName}`

  robotsValues.push(robotsIndex)
  robotsValues.push(robotsFollow)

  return (
    <Head>
      <title>{pageTitle}</title>
      {metaDescription && <meta name="description" content={metaDescription} />}
      <meta name="robots" content={robotsValues.join(', ')} />
      <link rel="canonical" href={pageUrl} />
      <meta property="og:url" content={ogUrl || pageUrl} />
      <meta property="og:type" content={ogType || 'website'} />
      <meta property="og:title" content={ogTitle || pageTitle} />
      <meta property="og:description" content={ogDescription || 'Shows and concerts at Sphere Las Vegas. An experience like no other.'} />
      {ogImage && ogImage.url && <meta property="og:image" content={ogImage.url} />}
    </Head>
  )
}

export default SEO
